<template>
  <div class="athleteview">
    <div id="left-pane">
      <WeekSummary v-bind:activities="activities"/>
    </div>
    <div id="athleteview-content">
      <template v-for="(activity, i) in activities.slice(1).slice(-5).reverse()">
        <ActivitySummary v-bind:key="i" v-bind:activity="activity"/>
      </template>
    </div>
  </div>
</template>

<script>
import WeekSummary from '@/views/dashboard/WeekSummary.vue';
import ActivitySummary from '@/views/activity/ActivitySummary.vue';

export default {
  name: 'athleteview',
  data() {
    return {
      activities: []
    };
  },
  methods: {

  },
  mounted: function(){
    this.get('/activities?athlete='+this.$route.params.athlete).then(res => {
      this.activities = res.activities;
      this.activities.forEach(activity => {
        activity.date = this.LocalDate.new(activity.date);
      });
    }).catch(err => {
      console.error(err);
    });
  },
  components: {
    WeekSummary,
    ActivitySummary
  },
}
</script>

<style scoped>

#athleteview-content{
  width: 800px;
  margin: auto;
}

#athleteview-content>*{
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
}

#left-pane{
  width: 240px;
  position: fixed;
  left: 10%;
}

#left-pane>*{
  background-color: white;
  border-radius: 5px;
}
</style>
