<template>
  <div class="box container">
    <p v-if="logged && this.$store.getters.user._id === coachId">
       {{ $t('coaching.to_invite') }} <br/>
        <b-button size="is-small" icon-right="content-copy" @click="copyLink()"/>
        <a :href="link">{{ link }}</a>
    </p>
    <div v-else>
      <div v-if="!error">
        <h5 class="title is-5" v-if="logged && $store.getters.user.coach">{{ $t('coaching.already_have_coach') }}</h5>
        <p class="mb-6 p-2"> <b>{{ coachName }}</b> {{ $t('coaching.want_to_coach') }}</p>
        <p v-if="!logged">{{ $t('coaching.login_to_accept') }}</p>
        <b-button v-if="logged" @click="accept()" size="is-large">{{ $t('general.accept') }}</b-button>
      </div>
      <p v-if="error" class="has-text-danger">
        <b>{{ $t('errors.error') }}:</b> {{ $t(error) }}
      </p>
    </div>
  </div>
</template>

<script>

export default{
  name: 'accept-coach',
  computed: {
    logged(){ return !!this.$store.getters.user; },
    link(){ return window.location.href; },
  },
  data(){
    return {
      coachId: this.$route.path.split('/').pop(),
      coachName: '',
      error: null,
    };
  },
  methods: {
    async accept(){
      try{
        await this.post('/accept_coach', { coach_id: this.coachId });
        this.$buefy.toast.open({
          duration: 2000,
          message: this.coachName + this.$t('coaching.is_now_your_coach'),
          position: 'is-bottom',
          type: 'is-success',
        });
        setTimeout(() => { this.$router.push('/dashboard'); }, 2000);
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    },

    copyLink(){
      navigator.clipboard.writeText(this.link);
      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('general.copied_to_clipboard'),
        position: 'is-bottom',
        type: 'is-success',
      });
    },
  },
  async mounted(){
    try{
      this.coachName = await this.get('/username_of_id?id='+this.coachId);

      if(!this.logged)
        localStorage.setItem('loginRedirection', this.$route.path);
    }catch(err){
      console.log(err);
      this.error = err;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
