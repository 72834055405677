<template>
  <div class="box container" v-if="$store.getters.hasCoachAccess">
    <div class="search-bar">
      <b-field>
        <b-input :placeholder="$t('general.search')+'...'" type="search" v-model="nameRequest"></b-input>
        <p class="control">
          <b-button class="button is-primary" v-on:click="search()">{{ $t('general.search') }}</b-button>
        </p>
      </b-field>

    </div>
    <p> {{ users.length }} displayed out of {{ total }} total </p>

    <ul class="user-list mt-5">
      <li v-for="(user, i) in users" :key="i" class="user columns is-vcentered">
        <div class="column">
          <h2 class="username has-text-left"><a @click="loadUser(user)">{{ user.username }}</a></h2>  <!--:href="'/athlete/'+user._id"-->
          <p class="has-text-left">{{ user.location ? user.location.name : '' }}</p>
          <p v-if="$store.getters.hasAdminAccess" class="has-text-left">{{ user.email }}</p>
        </div>
        <div class="column">
          <p>{{ user.nb_activities + ' ' + $t('activity.activities') }}</p>
        </div>
        <div class="column">
          <div v-if="$store.getters.hasAdminAccess && !hideAccountsTypes">
            <b-field label="AccountType">
              <p v-if="user.account_type === 'SUPERADMIN'">SUPERADMIN</p>
              <p v-if="user.account_type === 'ADMIN' && !$store.getters.hasSuperAdminAccess">ADMIN</p>
              <b-select v-if="user.account_type !== 'SUPERADMIN' && (user.account_type !== 'ADMIN' || $store.getters.hasSuperAdminAccess)"
                v-model="user.account_type" @change.native="updateUserAccountType(user)">
                <option value="BASE">BASE</option>
                <option value="STANDARD">STANDARD</option>
                <option value="PREMIUM">PREMIUM</option>
                <option value="TESTER">TESTER</option>
                <option value="COACH">COACH</option>
                <option value="BUSINESS">BUSINESS</option>
                <option value="ADMIN" v-if="$store.getters.hasSuperAdminAccess">ADMIN</option>
              </b-select>
            </b-field>
            <p v-if="user.account_type === 'PREMIUM' || user.account_type === 'STANDARD'">
              <b-checkbox v-model="user.has_trial" @input="changeTrial(user)">Trial</b-checkbox>
              <span v-if="!$store.getters.hasSuperAdminAccess">{{ user.premium_end_date.toLocaleDateString() }}</span>
              <span v-if="$store.getters.hasSuperAdminAccess">
                <b-datepicker v-model="user.premium_end_date" icon="calendar" editable trap-focus :first-day-of-week="1"
                  nearby-selectable-month-days :minDate="new Date()" @input="changePremiumEndDate(user)" class="is-inline-block"/>
              </span>
            </p>
          </div>

          <b-button v-if="searchCoachedAthletes" @click="revokeAthlete(user)">{{ $t('coaching.revoke_athlete') }}</b-button>
        </div>

        <div class="column is-narrow" v-if="$store.getters.hasSuperAdminAccess">
          <b-button @click="deleteUser(user)"><b-icon icon="delete"/></b-button>
        </div>
      </li>
    </ul>
    <b-pagination :total="total" :per-page="limit" v-model="page" @change="search"/>
  </div>
</template>

<script>
export default{
  name: 'search',
  props: {
    hideAccountsTypes: {
      type: Boolean,
      default: false,
    },
    searchCoachedAthletes: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      nameRequest: '',
      users: [],
      total: 0,
      limit: 100,
      page: 1,
    };
  },
  computed: {
    selfAccountType(){ return this.$store.getters.user.account_type; }
  },
  methods: {
    search(){
      var data = { searchQuery: this.nameRequest, page: this.page };
      const url = this.searchCoachedAthletes ? '/search_coached_athletes' : '/search';
      this.post(url, data).then(res => {
        this.users = res.users;
        this.total = res.total;
        this.limit = res.limit;

        for(let user of this.users)
          if(user.premium_end_date)
            user.premium_end_date = new Date(user.premium_end_date);
      }).catch(err => {
        console.error(err);
      });
    },

    updateUserAccountType(user){
      this.post('/change_account_type', { user_id: user._id, account_type: user.account_type }).then(()=>{
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Account Type changed',
          position: 'is-bottom',
          type: 'is-success'
        });
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      });
    },

    changePremiumEndDate(user){
      this.post('/change_premium_end_date', { user_id: user._id, premium_end_date: user.premium_end_date }).then(()=>{
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Premium end date changed',
          position: 'is-bottom',
          type: 'is-success'
        });
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      });
    },

    changeTrial(user){
      this.post('/change_trial', { user_id: user._id, trial: user.has_trial }).then(()=>{
        this.$buefy.toast.open({
          duration: 2000,
          message: 'User trial changed',
          position: 'is-bottom',
          type: 'is-success'
        });
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      });
    },



    loadUser(user){
      const user_id = (user._id == this.$store.getters.user._id) ? null : user._id;
      this.$store.dispatch('setLoadedUser', user_id).then(() => {
        this.$router.push('/dashboard');
      }).catch(err => {
        console.error(err);
      });
    },


    deleteUser(user){
      this.$buefy.dialog.prompt({
        message: 'Delete user ' + user.username + ' ? Type DELETE ' + user.username +' to confirm.',
        inputAttrs: {
          type: 'string',
          value: '',
        },
        trapFocus: true,
        onConfirm: (value) => {
          if(value === 'DELETE '+user.username){
            this.post('/delete_user', { user_id: user._id }).then(() => {
              this.$buefy.toast.open('User deleted !');
              this.search(); //reload
            }).catch(err => {
              this.$buefy.toast.open({ message: err, type: 'is-danger' });
            });
          }
        }
      });
    },



    async revokeAthlete(user){
      try{
        await this.post('/revoke_athlete', { user_id: user._id });
        this.search();
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    },


  },
  mounted(){
    //this.search();
  },
}
</script>

<style lang ="scss" scoped>
.user{
  border-top: solid grey 1px;
}
</style>
