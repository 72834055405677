<template>
  <div class="container" v-if="$store.getters.hasCoachAccess">
    <section class="section">
      <h3 class="title is-3">{{ $t('coaching.invite') }}</h3>
      <p> {{ $t('coaching.to_invite') }} <br/>
        <b-button size="is-small" icon-right="content-copy" @click="copyLink()"/>
        <a :href="link">{{ link }}</a> </p>
    </section>

    <section class="section">
      <h3 class="title is-3">{{ $t('coaching.athletes') }}</h3>
      <Search hideAccountsTypes searchCoachedAthletes />
    </section>
  </div>
</template>

<script>
import Search from './Search.vue';

export default{
  name: 'coaching-view',
  computed: {
    link(){ return process.env.VUE_APP_URL + '/accept_coach/' + this.$store.getters.user._id; },
  },
  data(){
     return {

     };
  },
  methods: {
    copyLink(){
      navigator.clipboard.writeText(this.link);
      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('general.copied_to_clipboard'),
        position: 'is-bottom',
        type: 'is-success',
      });
    },
  },
  components: {
    Search,
  },
}
</script>

<style lang="scss" scoped>
</style>
