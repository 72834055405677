<template>
  <div class="box container">
    <h1 class="title is-4">{{ username }}</h1>
    <section class="block">
      <p v-if="weekTime">{{ $t('plan.week_training_time') }} : {{ utimeToString(weekTime) }}</p>
    </section>
    <section class="block columns" v-if="daysTime && daysTime.length == 7">
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.monday') }}</h4>
        <p>{{ utimeToString(daysTime[0]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[0] }}</p>
      </div>
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.tuesday') }}</h4>
        <p>{{  utimeToString(daysTime[1]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[1] }}</p>
      </div>
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.wednesday') }}</h4>
        <p>{{  utimeToString(daysTime[2]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[2] }}</p>
      </div>
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.thursday') }}</h4>
        <p>{{  utimeToString(daysTime[3]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[3] }}</p>
      </div>
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.friday') }}</h4>
        <p>{{  utimeToString(daysTime[4]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[4] }}</p>
      </div>
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.saturday') }}</h4>
        <p>{{  utimeToString(daysTime[5]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[5] }}</p>
      </div>
      <div class="box column">
        <h4 class="title is-6">{{ $t('dates.sunday') }}</h4>
        <p>{{  utimeToString(daysTime[6]) }}</p>
        <p v-if="daysType && daysType.length === 7">{{ daysType[6] }}</p>
      </div>
    </section>
    <section class="block columns is-multiline" v-if="setModel">
      <h4 class="title is-6">{{ $t('metrics.set_model') }}</h4>
      <div class="column">
        <h5 class="title is-7">{{ $t('metrics.ftp') }}</h5>
        <p> {{ setModel.threshold_power }} </p>
      </div>
      <div class="column">
        <h5 class="title is-7">{{ $t('metrics.map') }}</h5>
        <p> {{ setModel.map }} </p>
      </div>
      <div class="column">
        <h5 class="title is-7">{{ $t('metrics.threshold_heart_rate') }}</h5>
        <p> {{ setModel.threshold_heart_rate }} </p>
      </div>
    </section>
    <section class="block columns is-multiline">
      <div class="column" v-if="trainingLevel != undefined">
        <h5 class="title is-7">{{ $t('plan.training_level') }}</h5>
        <p> {{ trainingLevel }} </p>
      </div>
      <div class="column" v-if="practiceYears!= undefined">
        <h5 class="title is-7">{{ $t('plan.practice_years') }}</h5>
        <p> {{ practiceYears }} </p>
      </div>
      <div class="column" v-if="age">
        <h5 class="title is-7">{{ $t('infos.age') }}</h5>
        <p> {{ age }} </p>
      </div>
      <div class="column" v-if="sex">
        <h5 class="title is-7">{{ $t('infos.sex') }}</h5>
        <p> {{ $t('infos.'+sex) }} </p>
      </div>
      <div class="column" v-if="weight">
        <h5 class="title is-7">{{ $t('metrics.weight') }}</h5>
        <p> {{ showWeight(weight) }} </p>
      </div>
    </section>
    <section class="block">
      <h4 class="title is-4">Moyennes par semaine</h4>
      <div class="columns is-multiline">
        <div class="column">
          <h5 class="title is-7">{{ $t('metrics.duration') }}</h5>
          <p> {{ utimeToString(durationPerWeek) }} </p>
        </div>
        <div class="column">
          <h5 class="title is-7">{{ $t('metrics.distance') }}</h5>
          <p> {{ showDistance(distancePerWeek) }} </p>
        </div>
        <div class="column">
          <h5 class="title is-7">{{ $t('metrics.load') }}</h5>
          <p> {{ Math.round(loadPerWeek) }} </p>
        </div>
      </div>
    </section>
    <section class="block">
      <h4 class="title is-5">{{ $t('objective.objectives')}}</h4>
      <template v-for="(objective, i) in objectives">
        <div class="block box" :key="i">
          <h5 class="title is-6">{{ objective.name }}</h5>
          <p>{{ objective.date.toLocaleDateString() }}</p>
          <section class="columns">
            <div class="column">
              <h5 class="title is-7">{{ $t('objective.priority') }}</h5>
              <p> {{ objective.priority }} </p>
            </div>
            <div class="column" v-if="objective.estimated_duration">
              <h5 class="title is-7">{{ $t('objective.estimated_duration') }}</h5>
              <p> {{ utimeToString(objective.estimated_duration) }} </p>
            </div>
            <div class="column" v-if="objective.distance">
              <h5 class="title is-7">{{ $t('metrics.distance') }}</h5>
              <p> {{ showDistance(objective.distance) }} </p>
            </div>
            <div class="column" v-if="objective.elevation">
              <h5 class="title is-7">{{ $t('metrics.elevation') }}</h5>
              <p> {{ showHeight(objective.elevation) }} </p>
            </div>
            <div class="column" v-if="objective.skills && objective.skills.length > 0">
              <h5 class="title is-7">{{ $t('objective.skills') }}</h5>
              <b-taglist>
                <b-tag v-for="skill in objective.skills" :key="skill">{{ $t('objective.'+skill) }}</b-tag>
              </b-taglist>
            </div>
          </section>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
export default{
  name: 'plan-data',
  data(){
    return {
      username: '',
      weekTime: undefined,
      daysTime: [],
      daysType: [],
      trainingLevel: undefined,
      practiceYears: undefined,
      age: undefined,
      sex: undefined,
      weight: undefined,
      objectives: [],
      durationPerWeek: 0,
      distancePerWeek: 0,
      loadPerWeek: 0,
      setModel: null,
    };
  },
  methods: {
    calcPerWeek(){
      this.durationPerWeek = 0;
      this.distancePerWeek = 0;
      this.loadPerWeek = 0;
      var activities = this.$store.getters.activities;
      if(activities.length == 0)
        return;

      //Get data as far back a a year max

      var lastActivity = activities[activities.length-1];
      var firstActivity = null;
      var firstActivityIndex = 0;
      for(let i=0; i<activities.length; i++){
        firstActivity = activities[i];
        firstActivityIndex = i;
        if(lastActivity.date.getTime() - firstActivity.date.getTime() <= 1000 * 3600 * 24 * 365)
          break;
      }

      for(let i=firstActivityIndex; i<activities.length; i++){
        let activity = activities[i];
        this.durationPerWeek += activity.duration;
        this.distancePerWeek += activity.data.distance || 0;
        this.loadPerWeek += activity.data.load || 0;
      }

      var nbWeeks = Math.ceil((lastActivity.date.getTime() - firstActivity.date.getTime())/(1000*3600*24*7));
      this.durationPerWeek /= nbWeeks;
      this.distancePerWeek /= nbWeeks;
      this.loadPerWeek /= nbWeeks;
    }
  },
  async mounted(){
    try{
      var uid = this.$store.getters.loadedUser;
      if(!uid)
        return;

      var data = (await this.get('/user_data?id='+uid)).user_data;

      this.username = data.username;

      if(data.training_plan_data){
        this.weekTime = data.training_plan_data.week_available_time;
        this.daysTime = data.training_plan_data.available_time_days;
        this.daysType = data.training_plan_data.training_type_days;
        this.practiceYears = data.training_plan_data.practice_years;
        this.trainingLevel = data.training_plan_data.training_level;
      }

      if(data.infos.birthday)
        this.age = this.LocalDate.now().yearsDiff(this.LocalDate.new(data.infos.birthday));

      if(data.infos.sex)
        this.sex = data.infos.sex;

      this.weight = this.ugetLastMeasure(this.LocalDate.now()).weight;

      this.objectives = this.$store.getters.objectives;

      this.calcPerWeek();

      if(data.set_model)
        this.setModel = data.set_model;

    }catch(err){
      console.error(err);
    }
  },
  components: {

  }
}
</script>

<style lang="scss">
</style>
